@use "sass:math";

@import "variables";

.payoutTable {
  display: grid;
  gap: 1%;
  padding-left: 1%;
  padding-right: 1%;
  grid-auto-columns: 1fr;
}

.resultEntry {
  grid-row: 1;
  text-align: center;
  border-radius: var(--bs-border-radius);
  padding: 5%;
  height: 1.25rem;
  font-size: 0.45rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--bs-secondary-text);
  position: relative;
  background-color: var(--bs-body-bg);
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    height: 1.5rem;
    font-size: 0.45rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 0.7rem;
  }
}

.multiplier {
  position: relative;
}


.colorStrip {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  transform: translateY(80%);
  transition-delay: 0.2s;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;
  transition-property: transform;
}

@keyframes resultAnimation {
  50% { transform: translateY(20%); }
  100% { transform: translateY(0); }
}

.resultEntry_show {
  transform: translateY(0%);
  animation-name: resultAnimation;
  animation-duration: 0.2s;
}

.colorStrip_show {
  transform: translateY(0%);
}

@function mix3($color1, $color2, $color3, $mix) {
  $color12: mix($color1, $color2, max($mix - 50%, 0%) * 2);
  @return mix($color12, $color3, min($mix * 2, 100%));
}

@for $i from 0 through 8 {
  .colorStrip-16-#{$i} {
    background-color: mix3($red, $yellow, $green, (1 - $i * 0.125) * 100%);
  }
}

@for $i from 0 through 6 {
  .colorStrip-12-#{$i} {
    background-color: mix3($red, $yellow, $green, (1 - math.div($i, 6)) * 100%);
  }
}

@for $i from 0 through 4 {
  .colorStrip-8-#{$i} {
    background-color: mix3($red, $yellow, $green, (1 - $i * 0.25) * 100%);
  }
}
