@import 'variables';

.footer {
  width: 100%;
  padding: 1em;
  display: grid;
  gap: 0.5rem;
}

.footerFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textarea {
  resize: none; // disable scroll bar
  overflow: hidden; // disable scroll bar
}
